import { Container } from 'swp-components/lib/components/bootstrap'
import Footer from 'swp-components/lib/components/footer'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Header from '@/components/header'
import Newrelic from '@/components/newrelic-agent'
import style from '@/components/news-events/homepage.style'
import SectionHeading from '@/components/section-heading'
import Seo from '@/components/seo'
import { MainTipsGrid } from '@/components/tips-grid'
import useNewsTipsDataLayer from '@/hooks/data-layers/news-tips/use-news-tips-page'
import strapi from '@/strapi'

const TOLTipsMain = ({ serverData }) => {
  let [
    seo,
    headerScript,
    brandHeaders,
    productServices,
    footerHeaders,
    chatShop,
    newsList,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)

  let arr = []

  let { handleMessage, createScopeProps } = useNewsTipsDataLayer()
  if (newsList.length > 0) {
    newsList.map((a, index) => {
      a.link = {
        url: `${serverData.locale !== 'th' ? '/en' : ''}/trueonline/tips/${
          a.slug
        }`,
        newTab: true,
      }
      a.startDate = a.updatedAt
      if (a.isHighlight === true) {
        arr.push(a)
      }
    })
    newsList.map(a => {
      if (a.isHighlight === false) {
        arr.push(a)
      }
    })
  }
  let customSeo = {
    title: 'รวมเคล็ด(ไม่)ลับใช้อินเตอร์เน็ตได้สนุก แบบไม่มีสะดุด | TrueOnline',
    metaDescription:
      'เคล็ดลับและเทคนิคการใช้งานอินเตอร์เน็ตให้เต็มสปีด ให้คุณสนุกได้ทุกกิจกรรมแบบไม่มีสะดุด ถูกรวบรวมมาไว้แล้วที่นี่',
  }

  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={customSeo} script={headerScript.script} />
        <Header items={brandHeaders} services={productServices} />

        {arr.length > 0 && (
          <>
            <div className="mt-5" css={style.box}>
              <SectionHeading tag="h1">{'QUICK TIPS'}</SectionHeading>
              <SectionHeading
                tag="h2"
                variant="underline"
                className="mt-1"
                level="2"
              >
                {'เคล็ดลับทิปส์เด็ด True Online'}
              </SectionHeading>
            </div>
            <Container className="mt-3 mb-5">
              <div css={style.box}>
                <MainTipsGrid
                  article={arr}
                  locale={serverData.locale}
                  path={'trueonline_tips_lists'}
                ></MainTipsGrid>
              </div>
            </Container>
          </>
        )}
        {footerHeaders && <Footer className="pb-4" items={footerHeaders} />}
        {chatShop && (
          <ChatShop
            content={chatShop}
            mariPlatform={ serverData.locale == 'th' ? "mari_tmh" : "truemoveh" }
            mariConfig={mariConfig}
          />
        )}
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default TOLTipsMain

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents(
    [
      'seo',
      'header-script',
      'brand-headers',
      'product-services',
      'footer-headers',
      'chat-and-shop',
      `tol-news-and-tips?_start=0&_limit=7`,
      'mari-chat-and-shop-config',
    ],
    pageContext.locale
  )

  let slug = params.slug
  return {
    props: { contents, slug: slug, locale: pageContext.locale },
  }
}
