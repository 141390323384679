import { css } from '@emotion/react'

const style = {
  wrapper: centered => css`
    display: flex;
    flex-wrap: wrap;
    margin: -0.25rem;

    @media (min-width: 768px) {
      margin: -0.5rem;
    }
  `,

  box: css`
    padding: 0.25rem;

    @media (min-width: 768px) {
      padding: 0.5rem;
    }
  `,

  card: row => css`
    width: auto;
    webkit-box-shadow: 0px 10px 10px 5px #f5f5f5;
    -moz-box-shadow: 0px 10px 10px 5px #f5f5f5;
    box-shadow: 0px 10px 10px 5px #f5f5f5;
    height: 100%;
  `,
}

export default style
