import { css } from '@emotion/react'

const style = {
  box: css`
    background-color: white;
  `,
  header: css`
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
    text-align: center;
  `,
}

export default style
