import axios from 'axios'
import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { breakpoints } from 'swp-components/lib/assets/config/style'
import { Container } from 'swp-components/lib/components/bootstrap'
import { Button, DataLayer } from 'swp-components/lib/components/common'
import { Card } from 'swp-components/lib/components/news'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import useNewsTipsDataLayer from '@/hooks/data-layers/news-tips/use-news-tips-page'
import useColumns from '@/hooks/use-columns'

import style from './style'

const MainTipsGrid = ({
  color,
  article,
  slug = '',
  path = '',
  locale = 'th',
  ...props
}) => {
  let [rows, setRows] = useState(1)
  let [art, setArt] = useState(article)
  let { columns } = useColumns([
    { value: breakpoints.sm, columns: 2 },
    { value: breakpoints.md, columns: 2 },
    { value: breakpoints.lg, columns: 3 },
  ])

  let centered = art.length < columns

  let highlightNews = art[0]
  art.map((a, index) => {
    if (index !== 0) {
      a.highlightImage = a.image
    }
  })

  let { handleMessage, createScopeProps } = useNewsTipsDataLayer()

  return (
    <DataLayerProvider handler={handleMessage}>
      <Container className="mb-5">
        {highlightNews != null && (
          <Row className="mb-5">
            <DataLayer
              selector={`QuickTips.Card#${highlightNews.id}`}
              triggers={['click']}
              context={highlightNews}
              render={ref => (
                <Col ref={ref}>
                  <Card
                    className="my-3"
                    css={style.card}
                    variant="hero"
                    article={highlightNews}
                  />
                </Col>
              )}
            />
          </Row>
        )}
        <div css={style.wrapper(centered)} {...props}>
          {art.map(
            (articles, index) =>
              index !== 0 && (
                <DataLayer
                  selector={`QuickTips.Card#${articles.id}`}
                  triggers={['click']}
                  context={articles}
                  key={articles.id}
                  render={ref => (
                    <div
                      ref={ref}
                      css={style.box}
                      style={{ width: `${100 / columns}%` }}
                    >
                      <Card
                        css={style.card}
                        color={color}
                        article={articles}
                        variant="hero"
                      />
                    </div>
                  )}
                />
              )
          )}
        </div>
        {(art.length === 7 || art.length === 6 * rows + 1) === true && (
          <div className="d-flex justify-content-center my-3">
            <DataLayer
              selector={`QuickTips.ViewAllButton`}
              triggers={['click']}
              render={ref => (
                <Button
                  ref={ref}
                  color="orange"
                  variant="outlined"
                  onClick={() => {
                    let fetchNewPage = axios({
                      method: 'post',
                      url: `/api/NewsEvents`,
                      data: {
                        url: `tol-news-and-tips?_start=${
                          rows * 6 + 1
                        }&_limit=${6}&_locale=${locale}`,
                      },
                    })

                    let arr = art
                    fetchNewPage
                      .then(function (response) {
                        return response.data
                      })
                      .then(a => {
                        a.map(b => {
                          b.link = {
                            url: `${
                              locale !== 'th' ? '/en' : ''
                            }/trueonline/tips/${b.slug}`,
                            newTab: true,
                          }
                          b.highlightImage = b.image
                          a.start_date = a.updated_at
                          arr.push(b)
                        })
                        setArt(arr)
                        setRows(rows + 1)
                      })
                  }}
                >
                  <FormattedMessage id="viewMore" defaultMessage="View More" />
                </Button>
              )}
            />
          </div>
        )}
      </Container>
    </DataLayerProvider>
  )
}

export default MainTipsGrid
