import usePageDataLayer from '../use-page-data-layer'

const handlePageMessage = ({ selector, context, trigger }, pushMessage) => {
  if (/^NewsEvent.Card#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_content',
      page_group: context.path,
      content_name: context.articles.title,
      section_name: 'News',
      content_type: 'News',
    })
  }

  if (selector === 'NewsEvent.ViewAllButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: context.path,
      button: 'View More',
      section_name: 'News',
    })
  }

  if (/^NewsEventDetail.Card#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_content',
      page_group: context.path,
      content_name: context.articles.title,
      section_name: 'News',
      content_type: 'News',
    })
  }

  if (selector === 'NewsEventDetail.ViewAllButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: context.path,
      button: 'View More',
      section_name: 'News',
    })
  }

  if (/^NewsEventTag.Card#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_content',
      page_group: context.path,
      content_name: context.articles.title,
      section_name: 'News',
      content_type: 'News',
    })
  }

  if (selector === 'NewsEventTag.ViewAllButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: context.path,
      button: 'View More',
      section_name: 'News',
    })
  }

  if (/^QuickTips.Card#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_content',
      page_group: 'trueonline_quicktip-list',
      content_name: context.title,
      section_name: 'Quick Tips',
      content_type: 'Quick Tips',
    })
  }

  if (selector === 'QuickTips.ViewAllButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: 'trueonline_quicktip-list',
      button: 'View More',
      section_name: 'Quick Tips',
    })
  }

  if (/^QuickTipsDetail.Card#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_content',
      page_group: 'trueonline_quicktip-detail',
      content_name: context.title,
      section_name: 'Quick Tips',
      content_type: 'Quick Tips',
    })
  }

  if (selector === 'QuickTipsDetail.ViewAllButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: 'trueonline_quicktip-detail',
      button: 'View More',
      section_name: 'Quick Tips',
    })
  }

  if (/^QuickTipsTag.Card#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_content',
      page_group: 'trueonline_quicktip-tag_list',
      content_name: context.title,
      section_name: 'Quick Tips',
      content_type: 'Quick Tips',
    })
  }

  if (selector === 'QuickTipsTag.ViewAllButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: 'trueonline_quicktip-tag_list',
      button: 'View More',
      section_name: 'Quick Tips',
    })
  }

  if (/^NewsEventDetailArticle\/Card.Tag#.+$/i.test(selector)) {
    pushMessage({
      event: 'related_tag',
      page_group: context.path,
      button: context.items.categoryName,
      content_name: context.items.categoryName,
      section_name: 'related_tag',
    })
  }

  if (selector === 'NewsEventDetailArticle/Card.navigatorShare') {
    pushMessage({
      event: 'social_share',
      page_group: context.path,
      content_name: context.article.title,
      button: 'Share',
    })
  }

  if (selector === 'NewsEventDetailArticle/Card.FacebookShare') {
    pushMessage({
      event: 'social_share',
      page_group: context.path,
      content_name: context.article.title,
      button: 'Facebook Share',
    })
  }

  if (selector === 'NewsEventDetailArticle/Card.TwitterShare') {
    pushMessage({
      event: 'social_share',
      page_group: context.path,
      content_name: context.article.title,
      button: 'Twitter Share',
    })
  }

  if (selector === 'NewsEventDetailArticle/Card.LineShare') {
    pushMessage({
      event: 'social_share',
      page_group: context.path,
      content_name: context.article.title,
      button: 'Line Share',
    })
  }

  if (selector === 'NewsEventDetailArticle/Card.BackButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: context.path,
      section_name: context.article.title,
      button: 'กลับ',
    })
  }

  if (/^QuickTipsDetailArticle\/Card.Tag#.+$/i.test(selector)) {
    pushMessage({
      event: 'related_tag',
      page_group: 'trueonline_quicktips-detail',
      button: context.items.categoryName,
      content_name: context.items.categoryName,
      section_name: 'related_tag',
    })
  }

  if (selector === 'QuickTipsDetailArticle/Card.navigatorShare') {
    pushMessage({
      event: 'social_share',
      page_group: 'trueonline_quicktips-detail',
      content_name: context.article.title,
      button: 'Share',
    })
  }

  if (selector === 'QuickTipsDetailArticle/Card.FacebookShare') {
    pushMessage({
      event: 'social_share',
      page_group: 'trueonline_quicktips-detail',
      content_name: context.article.title,
      button: 'Facebook Share',
    })
  }

  if (selector === 'QuickTipsDetailArticle/Card.TwitterShare') {
    pushMessage({
      event: 'social_share',
      page_group: 'trueonline_quicktips-detail',
      content_name: context.article.title,
      button: 'Twitter Share',
    })
  }

  if (selector === 'QuickTipsDetailArticle/Card.LineShare') {
    pushMessage({
      event: 'social_share',
      page_group: 'trueonline_quicktips-detail',
      content_name: context.article.title,
      button: 'Line Share',
    })
  }

  if (selector === 'QuickTipsDetailArticle/Card.BackButton') {
    pushMessage({
      event: 'call_to_action',
      page_group: 'trueonline_quicktips-detail',
      section_name: context.article.title,
      button: 'กลับ',
    })
  }
}

const useNewsTipsDataLayer = () => {
  let { createHandler, createScopeProps } = usePageDataLayer()
  let handleMessage = createHandler(handlePageMessage)
  return { handleMessage, createScopeProps }
}

export default useNewsTipsDataLayer
